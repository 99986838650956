export interface SignupProps {
	location: Location
};

export type SignupForm = {
	Groupname: string;
	Firstname: string;
	Lastname: string;
	Email: string;
	Password: string;
	confirmEmail: string;
	stateValue?: string;
	redirectUri?: string;
	codeValue?: string;
	confirmPassword: string;
} | {[key: string]: string};

export type SignupState = {
	error: string;
};

export const defaultSignupForm = {
	Firstname: '',
	Lastname: '',
	Email: '',
	Password: '',
	Groupname: '',
	confirmEmail: '',
	confirmPassword: '',
};

export const defaultSignupState: SignupState = {
	error: ''
};




