export interface SignupConfirmationProps {
    location: Location
}

export type SignupConfirmationState = {
    email: string;
    error: string;
    emailConfirmed: boolean;
}

export const defaultSignupConfirmationState: SignupConfirmationState = {
    email: '',
    error: '',
    emailConfirmed: false
}
