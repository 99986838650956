import React, {PureComponent} from 'react';
import {Box, MuiThemeProvider} from '@material-ui/core';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import theme from './ui/themes/AppTheme';
import AppBar from "./ui/AppBar/AppBar";
import Signup from "./ui/components/Signup/Signup";
import ChangePassword from "./ui/components/ChangePassword/ChangePassword";
import ForgotPassword from "./ui/components/ForgotPassword/ForgotPassword";
import Login from "./ui/components/Login/Login";
import SignupConfirmation from "./ui/components/SignupConfirmation/SignupConfirmation";

export default class App extends PureComponent {
	render() {
		return (
			<MuiThemeProvider theme={theme}>
				<Box>
					<AppBar />
					<Router>
						<Switch>
							<Route exact path="/" component={Signup}></Route>
							<Route path="/sign-up" component={Signup}></Route>
							<Route path="/forgotPassword" component={ForgotPassword}></Route>
							<Route path="/changePassword" component={ChangePassword}></Route>
							<Route exact={true} path="/success" component={SignupConfirmation}></Route>
						</Switch>
					</Router>
				</Box>
			</MuiThemeProvider>
		);
	}
}
