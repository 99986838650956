import React, {ChangeEvent, FC, ReactElement, useState} from 'react';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import {passwordSchema} from "./Signup.validations";
import { InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

interface PasswordFieldProps {
	Password: string,
	handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void,
	Disabled: boolean;
	hasError?: boolean;
}

const passwordErrorMessages: {[key: string]: string} = {
	lowercase: 'Password must contain an lower case letter',
	uppercase: 'Password must contain an upper case letter',
	symbols: 'Password must contain a special character',
	digits: 'Password must contain a number',
	min: 'Password must contain at least 8 characters',
};

const PasswordField: FC<PasswordFieldProps> = ({Password, handleInputChange, Disabled}): ReactElement => {
	const [passwordErrors, setPasswordErrors] = useState<string[]>([]);
	const [showPassword, setShowPassword] = useState(false);
	const handleClickShowPassword = () => setShowPassword(!showPassword);
	const handleMouseDownPassword = () => setShowPassword(!showPassword);

	const successMessages: string[] = Object.keys(passwordErrorMessages).filter((errorType: string) => !passwordErrors.includes(errorType));

	const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
		const validationRulesErrors: string[] = passwordSchema.validate(event.target.value, {list: true}) as string[];

		handleInputChange(event);
		setPasswordErrors(validationRulesErrors);
	};

	return (
		<Box className="form-group">
			<TextField fullWidth required id="Password" name="Password" label="Password"
					   type={showPassword ? "text" : "password"}
					   value={Password} onChange={handleChange}
					   error={passwordErrors.length > 0}
					   InputLabelProps={{
				style: {
					color: "#00144d",
					marginBottom: "5px"
				}
			}}
					   InputProps={{
						   style: {
							   marginBottom: "15px"
						   },
						   endAdornment: (
							   <InputAdornment position="end">
								   <IconButton
									   aria-label="toggle password visibility"
									   onClick={handleClickShowPassword}
									   onMouseDown={handleMouseDownPassword}
								   >
									   {showPassword ? <Visibility /> : <VisibilityOff />}
								   </IconButton>
							   </InputAdornment>
						   )
					   }}

					   disabled={Disabled} />
			{Password.length > 0 && passwordErrors && passwordErrors.map((error: string) => (
				<Box key={error} className="checkPassword-lowerletter passwordCheck-valid-customizable">
					{passwordErrorMessages[error] && <Box className="validation-message" component="span" color="error.main">✖</Box>}
					<Box className="validation-message" component="span"  color='error.main'>{passwordErrorMessages[error]}</Box>
				</Box>
			))}
			{Password.length > 0 && successMessages.map((error: string) => (
				<Box key={error} className="checkPassword-lowerletter passwordCheck-valid-customizable">
					{passwordErrorMessages[error] && <Box className="validation-message" component="span" color="success.main">✓</Box>}
					<Box className="validation-message" component="span" color="success.main">{passwordErrorMessages[error]}</Box>
				</Box>
			))}
		</Box>
	)
};

export default PasswordField;
