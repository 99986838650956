export const getServiceURL = (stage: string): string => {
    switch(stage) {
        case 'dev':
            return 'https://xa5kef29ci.execute-api.us-east-1.amazonaws.com/dev/api/';
        case 'test':
            return 'https://wfloit6fq2.execute-api.us-east-1.amazonaws.com/test/public/';
        case undefined:
        case 'local':
        case 'local_test':
        case 'local_prod':
            return 'http://127.0.0.1:8000/public/';
        case 'prod':
            return 'https://d2v37tzr33.execute-api.us-east-1.amazonaws.com/prod/api/';
        default:
            throw new Error(`unhandled stage = '${stage}'`);
    }
}

export const learningStreamDomain = (stage: string): string => {
    switch(stage) {
        case 'dev':
            return 'https://test.pennpath.upenn.edu/login';
        case 'test':
            return 'https://test.pennpath.upenn.edu/login';
        case undefined:
        case 'local':
        case 'local_test':
        case 'local_prod':
            return 'http://localhost:3000/login';
        case 'prod':
            return 'https://test.pennpath.upenn.edu/login';
        default:
            throw new Error(`unhandled stage = '${stage}'`);
    }
}

export const learningStreamURL = (stage: string): string => {
    switch(stage) {
        case 'dev':
            return 'https://xa5kef29ci.execute-api.us-east-1.amazonaws.com/dev/api/';
        case 'test':
            return 'https://test.gw.pennpath.upenn.edu/ls/learningstreamtest';
        case undefined:
        case 'local':
        case 'local_test':
        case 'local_prod':
            return 'https://test.gw.pennpath.upenn.edu/ls/learningstreamtest';
        case 'prod':
            return 'https://d2v37tzr33.execute-api.us-east-1.amazonaws.com/prod/api/';
        default:
            throw new Error(`unhandled stage = '${stage}'`);
    }
}
