import {AxiosInstance} from "axios";
import {SignupForm} from '../ui/components/Signup/Signup.models';
import {createAxiosService} from './AxiosService';
import {getServiceURL} from '../config/AppConfig';
// import {error} from "password-validator/typings/constants";
// import password = error.password;

const baseUrl = getServiceURL(process.env.REACT_APP_STAGE);

export default class SignupService {
    public axiosService: AxiosInstance;
    public groupName: string;

    constructor() {
        this.axiosService = createAxiosService(getServiceURL(process.env.REACT_APP_STAGE));
    }

    public signupUser(user: SignupForm): Promise<any> {
        const url: string = baseUrl + 'signup_user/';
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(user)
            }).then(async (result: any) => {
                const data = await result.json();

                if (result.status >= 200 && result.status < 300) {
                    resolve(data);
                } else {
                    reject({status: result.status, data: data});
                }
            });
        });

    }

    public async resendEmail(email: string): Promise<void> {
        const url: string = 'resend_email/';
        await this.axiosService.post(url, {Email: email, Groupname: window.sessionStorage.getItem('groupname')});
    }

    async verifyEmail(email: string): Promise<void> {
        const url: string = 'verify_email/';
        await this.axiosService.post(url, {Email: email});
    }

    async signin(email: string): Promise<void> {
        const url: string = 'signin/';
        await this.axiosService.post(url, {Username: email, Password: 'password', Groupname: window.sessionStorage.getItem('groupname')});
    }

    public setGroupName(value: string) {
        this.groupName = value;
    }

    public sendGroupName() {
        return this.groupName;
    }
}
