import React, {ChangeEvent, FC, memo, ReactElement, useEffect, useState} from 'react';
import {RouterProps} from "react-router";
import {Button, Box, createStyles, makeStyles, TextField, Theme} from '@material-ui/core';
import {useFormik} from 'formik';
import {SignupState, SignupForm, defaultSignupState, defaultSignupForm, SignupProps} from './Signup.models';
import {isValid, passwordSchema, formatPasswordValidateError, validationSchema} from './Signup.validations';
import PasswordField from './PasswordField';
import SignupService from '../../../services/SignupService';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        submitButton: {
            marginTop: 10
        },
        boxShadow: {
            boxShadow: '0px 0px 8px 3px rgba(0,0,0,0.36)'
        }
    }));

const signupService: SignupService = new SignupService();

const Signup: FC<SignupProps & RouterProps> = ({location, history}): ReactElement<SignupProps> => {
    const [state, setState] = useState<SignupState>(defaultSignupState);
    const classes = useStyles();

    const signupForm = (values: SignupForm): void => {
        const {errors} = formik;

        if (isValid(errors, values)) {
            values.stateValue = window.sessionStorage.getItem('stateValue');
            signupService.signupUser(values)
                .then((res) => {
                    history.push(`/success?email=${Email}`);
                })
                .catch((error: any) => {
                    console.log(error);
                    if (error.status >= 400 && error.status < 500) {
                        // setState({error: error.data.Message});
                        setState({error: 'This email address is associated with an existing account.'});
                    } else {
                        setState({error: 'Something went wrong. Please try again after sometime.'});
                    }
                });
        } else {
            console.error('Invalid Form');
        }
    };

    const formik = useFormik({
        initialValues: {...defaultSignupForm},
        enableReinitialize: true,
        validationSchema: validationSchema,
        validateOnChange: true,
        validateOnBlur: true,
        onSubmit: signupForm
    });

    useEffect(() => {
        const {search} = location;
        if (search) {
            const groupName: string = (search.split('=')[1]).replace('&redirect_uri', '')
            formik.setValues({...formik.values, Groupname: groupName})
            let spliValues = search.split('=');
            let stateValue: string = spliValues[spliValues.length - 1];
            let redirectUri: string = spliValues[spliValues.length -1];
            let codeValue:string = spliValues[spliValues.length -1]
            signupService.setGroupName(groupName);
            window.sessionStorage.setItem("groupname", groupName);
            window.sessionStorage.setItem("stateValue", stateValue);
            window.sessionStorage.setItem("redirect_uri", redirectUri);
            window.sessionStorage.setItem("CODE", codeValue)
        }
    }, [location]);

    const handleChange = (event: ChangeEvent<HTMLInputElement> | any): void => {
        formik.handleChange(event);
        setTimeout(() => validateOnChange(event), 200);
    };

    const confirmErrorEmail = (event: ChangeEvent<HTMLInputElement> | any): void => {
        const {value} = event.target;
        const {Email} = formik.values;
        const {errors, setErrors} = formik;
        if (!errors.confirmEmail && value !== Email) {
            setErrors({...errors, confirmEmail: 'Email address doesn\'t match'});
        }
    }

    const getConfirmedEmail = (): boolean => {
        return confirmEmail !== Email ? true : false;
    }

    const validateOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const {name, value} = event.target;
        const {Password} = formik.values;
        const {errors, setErrors} = formik;

        switch (name) {
            case 'Password':
                const validationRulesErrors: any = passwordSchema.validate(value, {list: true});
                if (Array.isArray(validationRulesErrors) && validationRulesErrors.length > 0) {
                    setErrors({...errors, Password: formatPasswordValidateError(validationRulesErrors)});
                }
                break;
            case 'confirmPassword':
                if (!errors.confirmPassword && value !== Password) {
                    setErrors({...errors, confirmPassword: 'Passwords don\'t match'});
                }
                break;
            default:
                break;
        }
    }

    const {
        error
    } = state;

    const {
        values: {
            Firstname,
            Lastname,
            confirmPassword,
            confirmEmail,
            Password,
            Email
        },
        handleSubmit,
        errors,
        values
    } = formik;

    return (
        <Box>
            <Box className={`${classes.boxShadow} padding-content`}>
                <form onSubmit={handleSubmit} noValidate>
                    <h3 className="header-bottom" data-testid={"label"}>Sign up with a new account</h3>
                    <TextField fullWidth={true} type="text" id="Firstname" name="Firstname" label="First Name"
                               data-testid={"Firstname"} required
                               value={Firstname} onChange={handleChange}
                               helperText={errors.Firstname}
                               InputLabelProps={{
                                   style: {
                                       color: "#00144d",
                                       marginBottom: "5px"
                                   }
                               }}
                               InputProps={{
                                   style: {
                                       marginBottom: "15px"
                                   }
                               }}
                               error={Boolean(errors.Firstname)}/>
                    <TextField fullWidth type="text" id="Lastname" name="Lastname" label="Last Name"
                               data-testid={"Lastname"} required value={Lastname}
                               onChange={handleChange}
                               helperText={errors.Lastname}
                               InputLabelProps={{
                                   style: {
                                       color: "#00144d",
                                       marginBottom: "5px"
                                   }
                               }}
                               InputProps={{
                                   style: {
                                       marginBottom: "15px"
                                   }
                               }}
                               error={Boolean(errors.Lastname)}/>
                    <TextField fullWidth type="email" id="Email" name="Email" label="Email" data-testid={"Email"}
                               required value={Email}
                               onChange={handleChange}
                               helperText={errors.Email}
                               InputLabelProps={{
                                   style: {
                                       color: "#00144d",
                                       marginBottom: "5px"
                                   }
                               }}
                               InputProps={{
                                   style: {
                                       marginBottom: "15px"
                                   }
                               }}
                               error={Boolean(errors.Email)}/>
                    <TextField fullWidth type="email" id="confirmEmail" name="confirmEmail" label="Confirm Email"
                               data-testid={"confirmEmail"} required
                               value={confirmEmail} onChange={handleChange} onBlur={confirmErrorEmail}
                               helperText={errors.confirmEmail}
                               InputLabelProps={{
                                   style: {
                                       color: "#00144d",
                                       marginBottom: "5px"
                                   }
                               }}
                               InputProps={{
                                   style: {
                                       marginBottom: "15px"
                                   }
                               }}
                               error={Boolean(errors.confirmEmail)}/>
                    <PasswordField Password={Password} data-testid={"Password"}  Disabled={getConfirmedEmail()}
                                   handleInputChange={handleChange}/>
                    <TextField fullWidth type="password" id="confirmPassword" name="confirmPassword"
                               label="Confirm Password"
                               className="field-style m-t-7"
                               required value={confirmPassword} onChange={handleChange} onBlur={handleChange}
                               helperText={errors.confirmPassword} data-testid={"confirmPassword"}
                               InputLabelProps={{
                                   style: {
                                       color: "#00144d",
                                       marginBottom: "5px"
                                   }
                               }}
                               InputProps={{
                                   style: {
                                       marginBottom: "15px"
                                   }
                               }}
                               disabled={getConfirmedEmail()}
                               error={Boolean(errors.confirmPassword)}/>
                    {error && <Box color="error.main">{error}</Box>}
                    <Button
                        className={classes.submitButton}
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        data-testid={"submit"}
                        disabled={!isValid(errors, values)}
                    >
                        Sign up
                    </Button>
                </form>
            </Box>
        </Box>
    );
}

export default memo(Signup);
