import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

/**
 * This module is intended to provide convenient authorized access to the serverless backend,
 * via the interceptor picking up the Cognito idToken from session storage.
 *
 * Since we set the baseUrl here, devs need only supply the relative url.
 *
 * Note that axios will (pretty) transparently merge configs passed to this instance, on top
 * of the config placed here already.
 *
 * Note that other frameworks (eg Angular Fullstack) employ interceptors to secure idTokens,
 * the idea is to prevent the idToken from being held someplace where it might leak or be XSS'd
 */
let axiosService: AxiosInstance;

/**
 * This is the function invoked by auth scripts to set up the axios instance used for secure
 * API access, for details see {@link axiosService}.
 * @param _baseUrl
 * @param idToken
 * @param idTokenKey
 */
export const createAxiosService = (_baseUrl: string): AxiosInstance => {
  axiosService =  axios.create({
    baseURL: _baseUrl
  });

  axiosService.interceptors.request.use((_config: AxiosRequestConfig): AxiosRequestConfig => {
    // create a copy so the original object is unchanged
    let axiosRequestConfig = {..._config};

    let _headers = axiosRequestConfig.headers;
    _headers = _headers || {};
    axiosRequestConfig.headers = {
      ..._headers};

    return axiosRequestConfig;
  });
  axiosService.interceptors.response.use((data: any) => {
    // create a copy so the original object is unchange
    return data;
  }, (error: any) => {
    return Promise.reject(error);
  });

  return axiosService;
};





/**
 * Get an axios instance that's been set up for you to access the secure serverless API Gateway.
 * For details, see {@link axiosService}.
 */
export const getAxiosService = (): AxiosInstance => axiosService;
