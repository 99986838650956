import {createTheme, Theme} from "@material-ui/core";

const theme: Theme = createTheme({
	typography: {
		fontSize: 14
	},
	palette: {
		primary: {
			main: "#00144d"
		},
		secondary: {
			main: "#95001a"
		}
	}
});

export default theme;
