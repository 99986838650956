import React, {ChangeEvent, FC, memo, ReactElement, useEffect, useState} from 'react';
import {Button, Box, createStyles, makeStyles, TextField, Theme} from '@material-ui/core';
import {FormikErrors, useFormik} from 'formik';
import {object, SchemaOf, string} from "yup";
import {RouterProps} from "react-router";
import {formatPasswordValidateError, passwordSchema} from "../Signup/Signup.validations";
import PasswordField from "../Signup/PasswordField";

export interface ChangePasswordForm {
    Code: string;
    Password: string;
    ConfirmPassword?: string;
}

export interface ChangePasswordProps {
    location: Location
}

export interface ChangePasswordState {
    error?: string;
    email?: string;
}

const isValid = (errors: FormikErrors<ChangePasswordForm> | any, data: ChangePasswordForm | any) => {
    return Boolean(data) && Object.keys(data).every((key) => data[key].length > 0) &&
        Object.values(errors).every((error) => Boolean(error));
};

export const validationSchema: SchemaOf<ChangePasswordForm> = object({
    ConfirmPassword: string().required('ConfirmPassword is required').max(50, 'ConfirmPassword cannot be more than 50 characters'),
    Code: string().required('Code is required').max(50, 'Code cannot be more than 50 characters'),
    Password: string().required('Password is required').max(50, 'Password cannot be more than 50 characters')
}).defined();

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        infoMessage: {
            marginTop: 16
        },
        submitButton: {
            marginTop: 10
        },
        boxShadow: {
            boxShadow: '0px 0px 8px 3px rgba(0,0,0,0.36)'
        },
        error: {
            color: 'red',
            border: 'solid 2px red',
            padding: '8px',
            margin: '16px 0 16px'
        }
    }));

const ChangePassword: FC<ChangePasswordProps & RouterProps> = ({location, history}): ReactElement => {
    const [state, setState] = useState<ChangePasswordState>({error: ''});
    const classes = useStyles();
    const {
        error
    } = state;

    useEffect(() => {
        const {search} = location || {};
        if (search) {
            const groupName: string = search.split('=')[1];
            setState({...state, email: groupName});
        }
    }, [location]);

    const changePasswordUser = (values: ChangePasswordForm): void => {
        const {errors} = formik;

        if (isValid(errors, values)) {
            const {Code, Password} = values;
            setState({...state, error: ''});

            // Auth.forgotPasswordSubmit(state.email, Code, Password)
            //     .then(user => {
            //         history.push('/login');
            //     })
            //     .catch((error: any) => {
            //         console.log('Login failed', error);
            //         setState({...state, error: error.message});
            //     });
        } else {
            console.error('Invalid Form', errors);
            setState({...state, error: 'Invalid Details'});
        }
    };

    const formik = useFormik({
        initialValues: { Code: '', Password: '', ConfirmPassword: ''},
        enableReinitialize: true,
        validationSchema: validationSchema,
        validateOnChange: true,
        validateOnBlur: true,
        onSubmit: changePasswordUser
    });

    const validateOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const {name, value} = event.target;
        const {Password} = formik.values;
        const {errors, setErrors} = formik;

        switch (name) {
            case 'Password':
                const validationRulesErrors: any = passwordSchema.validate(value, {list: true});
                if (validationRulesErrors.length > 0) {
                    setErrors({...errors, Password: formatPasswordValidateError(validationRulesErrors)});
                }
                break;
            case 'ConfirmPassword':
                if (!errors.ConfirmPassword && value !== Password) {
                    setErrors({...errors, ConfirmPassword: 'Passwords don\'t match'});
                }
                break;
            default:
                break;
        }
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement> | any): void => {
        formik.handleChange(event);
        setTimeout(() => validateOnChange(event), 200);
    };

    const maskEmail = (email: string): string => {
        if (email) {
            const emailParts: string[] = email.split('@');
            return `${emailParts[0][0]}***@${emailParts[1][0]}***.${email.split('.')[1]}`;
        } else {
            return  "";
        }
    };

    const {
        values: {
            Code,
            Password,
            ConfirmPassword
        },
        handleSubmit,
        errors
    } = formik;

    return (
        <Box>
            <Box className={`${classes.boxShadow} padding-content`}>
                <form onSubmit={handleSubmit} noValidate>
                    {error && <div className={classes.error}>{error}</div>}
                    <div className={classes.infoMessage}>We have sent a password reset code by email it {maskEmail(state.email)}. Enter it below to reset your password</div>
                    <TextField fullWidth id="Code" name="Code" label="Code"
                               data-testid="Code" required
                               value={Code} onChange={handleChange}
                               error={Boolean(errors.Code)}
                               InputLabelProps={{
                                   style: {
                                       color: "#00144d",
                                       marginBottom: "5px"
                                   }
                               }}
                               InputProps={{
                                   style: {
                                       marginBottom: "15px"
                                   }
                               }}/>
                    <PasswordField Password={Password} data-testid={"Password"} Disabled={false}
                                   handleInputChange={handleChange} hasError={false}/>
                    <TextField fullWidth type="password" id="ConfirmPassword"
                               name="ConfirmPassword" label="Enter New Password Again"
                               data-testid="ConfirmPassword" required
                               value={ConfirmPassword} onChange={handleChange}
                               error={Boolean(errors.ConfirmPassword)}
                               InputLabelProps={{
                                   style: {
                                       color: "#00144d",
                                       marginBottom: "5px"
                                   }
                               }}
                               InputProps={{
                                   style: {
                                       marginBottom: "15px"
                                   }
                               }}/>

                    <Button
                        className={classes.submitButton}
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        data-testid={"submit"}
                    >
                        Change Password
                    </Button>
                </form>
            </Box>
        </Box>
    );
}

export default memo(ChangePassword);
