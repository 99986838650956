import React, {FC, ReactElement} from 'react';
import {Box} from '@material-ui/core';
import logo from "../../assets/images/logo.jpg";
import './../../index.css';

const AppBar: FC = (): ReactElement => {
	return (
		<Box className="app-bar">
			<img src={logo} alt="logo" className="logo-style"/>
		</Box>
	);
};

export default AppBar;
