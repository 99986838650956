import {string, object, SchemaOf} from 'yup';
import PasswordValidator from 'password-validator';
import {SignupForm} from './Signup.models';
import {FormikErrors} from "formik";

export const isValid = (errors: FormikErrors<SignupForm> | any, data: SignupForm | any) => {
	return Boolean(data) && Object.keys(data).every((key) => data[key].length > 0) &&
		Object.values(errors).every((error) => Boolean(error));
};

export const passwordSchema: PasswordValidator = new PasswordValidator();

passwordSchema
	.is()
	.min(8)
	.has()
	.uppercase()
	.has()
	.lowercase()
	.has()
	.digits()
	.has()
	.symbols();

export const formatPasswordValidateError = (errors: string[]) => {
	for (let i = 0; i < errors.length; i++) {
		if (errors[i] === 'min') {
			return 'password length should be a at least 8 characters';
		} else if (errors[i] === 'lowercase') {
			return 'password should contain lowercase letters';
		} else if (errors[i] === 'uppercase') {
			return 'password should contain uppercase letters';
		} else if (errors[i] === 'digits') {
			return 'password should contain digits';
		} else if (errors[i] === 'symbols') {
			return 'password should contain symbols';
		}
	}
};

export const validationSchema: SchemaOf<SignupForm> = object({
	Username: string().nullable(),
	Groupname: string().defined(),
	Firstname: string().max(50, 'First Name cannot be more than 50 Characters'),
	Lastname: string().max(50, 'Last Name cannot be more than 50 Characters'),
	Email: string().email('Please enter a valid email address'),
	confirmEmail: string().nullable(),
	Password: string().max(50, 'Password cannot be more than 50 Characters'),
	confirmPassword: string().min(2, 'Please re-enter your password'),
	stateValue: string().nullable(),
	redirectUri: string().nullable(),
	codeValue: string().nullable()

}).defined();
