import React, {FC, memo, ReactElement, useState} from 'react';
import {Button, Box, createStyles, makeStyles, TextField, Theme} from '@material-ui/core';
import {FormikErrors, useFormik} from 'formik';
import {object, SchemaOf, string} from "yup";
import {RouteProps, RouterProps} from "react-router";

export interface ForgotPasswordForm {
    Username: string;
}

export interface ForgotPasswordProps extends RouterProps {
};


export interface ForgotPasswordState {
    error?: string
}

const isValid = (errors: FormikErrors<ForgotPasswordForm> | any, data: ForgotPasswordForm | any) => {
    return Boolean(data) && Object.keys(data).every((key) => data[key].length > 0) &&
        Object.values(errors).every((error) => Boolean(error));
};

export const validationSchema: SchemaOf<ForgotPasswordForm> = object({
    Username: string().required('Username is required').max(50, 'Username cannot be more than 50 characters')
}).defined();

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerTitle: {
            fontSize: '36px',
            marginTop: 20,
            marginBottom: 10,
            fontWeight: 500,
            color: '#3B3B3B'
        },
        description: {
            color: '#3B3B3B',
            lineHeight: '20px'
        },
        submitButton: {
            marginTop: 10
        },
        boxShadow: {
            boxShadow: '0px 0px 8px 3px rgba(0,0,0,0.36)'
        },
        error: {
            color: 'red'
        }
    }));

const ForgotPassword: FC<ForgotPasswordProps> = ({history}): ReactElement<ForgotPasswordProps> => {
    const [state, setState] = useState<ForgotPasswordState>({error: ''});
    const classes = useStyles();
    const {
        error
    } = state;

    const forgotPasswordUser = (values: ForgotPasswordForm): void => {
        const {errors} = formik;

        if (isValid(errors, values)) {
            const {Username} = values;
            setState({error: ''});

            // Auth.forgotPassword(Username)
            //     .then(user => {
            //         history.push(`/changePassword?email=${Username}`);
            //     })
            //     .catch((error: any) => {
            //         setState({error: 'Invalid Username'});
            //     });
        } else {
            setState({error: 'Invalid Username'});
        }
    };

    const formik = useFormik({
        initialValues: {Username: ''},
        enableReinitialize: true,
        validationSchema: validationSchema,
        validateOnChange: true,
        validateOnBlur: true,
        onSubmit: forgotPasswordUser
    });

    const {
        values: {
            Username
        },
        handleSubmit,
        errors,
        handleChange
    } = formik;

    return (
        <Box>
            <Box className={`${classes.boxShadow} padding-content`}>
                <form onSubmit={handleSubmit} noValidate>
                    <h1 className={classes.headerTitle} data-testid={"label"}>
                        Forgot Your Password?
                    </h1>
                    <div className={classes.description}>Enter your Username below and we will send a message to reset your password</div>
                    {error && <div className={classes.error}>{error}</div>}

                    <TextField fullWidth id="Username" name="Username" label="Username"
                               data-testid="Username" required
                               value={Username} onChange={handleChange}
                               error={Boolean(errors.Username)}
                               InputLabelProps={{
                                   style: {
                                       color: "#00144d",
                                       marginBottom: "5px"
                                   }
                               }}
                               InputProps={{
                                   style: {
                                       marginBottom: "15px"
                                   }
                               }}/>

                    <Button
                        className={classes.submitButton}
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        data-testid={"submit"}
                    >
                        Reset my Password
                    </Button>
                </form>
            </Box>
        </Box>
    );
}

export default memo(ForgotPassword);
