import React, {FC, memo, ReactElement, useEffect, useState} from 'react';
import {Button, Box, createStyles, makeStyles, Link, Typography} from '@material-ui/core';
import {
    SignupConfirmationState,
    SignupConfirmationProps,
    defaultSignupConfirmationState
} from './SignupConfirmation.models';
import SignupService from '../../../services/SignupService';
import {getServiceURL, learningStreamDomain, learningStreamURL} from '../../../config/AppConfig';
import axios from "axios";


const useStyles = makeStyles(() =>
    createStyles({
        submitButton: {
            marginTop: 10
        },
        error: {
            color: 'red',
            border: 'solid 1px red',
            padding: 10,
            marginBottom: 10,
            marginTop: 15
        },
        boxShadow: {
            boxShadow: '2px 2px 8px 3px rgba(0,0,0,0.36)'
        }
    }));

const signupService: SignupService = new SignupService();

const SignupConfirmation: FC<SignupConfirmationProps> = ({location}): ReactElement => {
    const [state, setState] = useState<SignupConfirmationState>(defaultSignupConfirmationState);
    const classes = useStyles();

    useEffect(() => {
        const {search} = location || {};
        if (search) {
            const groupName: string = search.split('=')[1];
            setState({...state, email: groupName});
        }
    }, [location]);

    const handleSubmit = (): void => {
        if (Boolean(state.error)) {
            setState({...state, error: ''});
        }
        axios.post(getServiceURL(process.env.REACT_APP_STAGE) + 'verify_email/', {Email: state.email, Groupname: window.sessionStorage.getItem('groupname')}).then((res) => {
            if (res && res.data && res.data.UserStatus === 'UNCONFIRMED') {
                setState({
                    ...state,
                    error: 'Your account must be confirmed before you can continue. Please check your email and follow the instructions.'
                });
            } else {
                // setState({...state, emailConfirmed: true});
                let stateValue = window.sessionStorage.getItem('stateValue');
                let generatedURL = decodeURI(learningStreamDomain(process.env.REACT_APP_STAGE) + '?client_id='+ res.data.app_client_id + '&redirect_uri=' + learningStreamURL(process.env.REACT_APP_STAGE)
                    +'&response_type=code&state=' + stateValue)
                window.open(generatedURL, '_self');
            }
        }).catch(() => {
            setState({
                ...state,
                error: 'Your account must be confirmed before you can continue. Please check your email and follow the instructions.'
            });
        });
    };

    const resendEmail = (): Promise<void> => {
        if (Boolean(state.error)) {
            setState({...state, error: ''});
        }

        return signupService.resendEmail(state.email).then(res => {
            console.log('resendEmail', res);
        }).catch(() => {
            setState({
                ...state,
                error: 'Error while resending email.'
            });
        });
    };

    const maskEmail = (email: string): string => {
        const emailParts: string[] = email.split('@');
        return `${emailParts[0][0]}***@${emailParts[1][0]}***.${email.split('.')[1]}`;
    };

    return state.email && (
        <Box className={`${classes.boxShadow} padding-content`}>
            {!state.emailConfirmed && (
                <Box>
                    {state.error && <Typography component="p" className={classes.error}>{state.error}</Typography>}
                    <Typography component="p" className="text-center m-t-20">
                        We have sent an email to {maskEmail(state.email)}. Please check your email, follow the
                        instructions to verify your email address, and then click the button below to
                        continue.</Typography>

                    <Button
                        className={classes.submitButton}
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={handleSubmit}
                        data-testid={"continue"}
                    >
                        Continue
                    </Button>
                    <br/>
                    <br/>
                    <Typography component="span">Didn't receive a link? </Typography>
                    <Link href="#" onClick={resendEmail}>
                        Click Here to Resend
                    </Link>
                </Box>
            )}
            {state.emailConfirmed && (
                <Box>
                    <Typography component="p" className="text-center m-t-20 success-registered">
                        Welcome to PennPath, You have successfully registered.
                    </Typography>
                </Box>
            )}
        </Box>
    );
}


export default memo(SignupConfirmation);
